import React from 'react';
import { Form, Input, TreeSelect,  InputNumber, Tag, Radio } from 'antd';
import { CloseSquareOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import WebApi from '@/web/api';
import { Button, UploadImage } from '@/components';
import formRules from './form_rules';
import { LibICategoryItem, LibIProductUnit } from 'lib/interface/ProductInterface';
import { v4 } from 'uuid';
import LibEnum from 'lib/enum';
import classNames from 'classnames';
const styles = require('./product_form.less');

interface IProductFormProps {
  productId?: string;
  status?: string;
  productName?: string;
  barcode?: string;
  categoryId?: string;
  minBuyCount?: number;
  sortValue?: number;
  productDesc?: string;
  searchWordList?: string[];
  hideUnit?: boolean;
  hideSku?: boolean;
  hideImage?: boolean;
}

interface IState {
  loading: boolean;
  category: LibICategoryItem[];
  unitList: {
    unitId?: string;
    unitName: string | undefined;
    ratio: number | undefined;
    unitNameError: boolean;
    ratioError: boolean;
    key: string;
  }[];
  unitError: boolean;
  skuList: {
    skuId?: string;
    skuName?: string;
    price?: string | number;
    skuNameError: boolean;
    priceError: boolean;
    key: string;
  }[];
  skuError: boolean;
  searchWordList: string[];
  showSearchWordInput: boolean;
  imageError: boolean;
};

export default class ProductForm extends React.PureComponent<IProductFormProps, IState> {

  private categoryIdList: any[]
  private form: FormInstance | null = null;
  private saveInputRef = React.createRef<Input>();
  private logoImage = React.createRef<UploadImage>();
  private slideImage = React.createRef<UploadImage>();
  private detailImage = React.createRef<UploadImage>();

  constructor(props) {
    super(props);
    this.state = {
      category: [],
      loading: true,
      searchWordList: props.searchWordList || [],
      unitList: props.unitList || [{
        unitName: undefined,
        ratio: 1,
        key: v4(),
        unitNameError: false,
        ratioError: false,
      }],
      unitError: false,
      skuList: props.skuList || [{
        skuId: undefined,
        skuName: undefined,
        price: undefined,
        key: v4(),
        skuNameError: false,
        priceError: false,
      }],
      skuError: false,
      imageError: false,
      showSearchWordInput: false,
    };

    this.categoryIdList = [];
  }

  componentDidMount() {
    this.findCategory();
  }

  findCategory = () => {
    WebApi.category_list()
    .then(data => {
      this.setState({
        category: data.map(item => {
          return {
            title: item.categoryName,
            value: item.categoryId,
            disabled: true,
            children: item.children.map(child => {
              return {
                title: child.categoryName,
                value: child.categoryId,
              }
            }),
          };
        }),
        loading: false,
      });
    });
  }

  render() {
    const { loading } = this.state;
    return <div className="product-form">
      <div className="block-title"><i className="iconfont icon-biaodan1"/>基本信息</div>
      <Form ref={r => this.form =r} initialValues={{
        productName: this.props.productName,
        categoryId: this.props.categoryId,
        barcode: this.props.barcode,
        minBuyCount: this.props.minBuyCount,
        sortValue: this.props.sortValue,
        productDesc: this.props.productDesc,
        status: this.props.status,
      }}>
        <div className="product-form-row">
          <Form.Item label="商品名称" name="productName" rules={formRules.productName}>
            <Input placeholder="小于64个字符" maxLength={64} />
          </Form.Item>
          <Form.Item label="商品分类" name="categoryId" rules={formRules.categoryId}>
            <TreeSelect
              bordered={false}
              loading={loading}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="选择商品分类"
              treeDefaultExpandAll
              treeData={this.state.category}
            ></TreeSelect>
          </Form.Item>
          <Form.Item label="条形码" name="barcode">
            <Input/>
          </Form.Item>
        </div>

        <div className="product-form-row">
          <Form.Item label="起购数量" name="minBuyCount" rules={formRules.minBuyCount}>
            <InputNumber
              precision={0}
              // type="number"
              style={{width: '100%'}}
              placeholder="起购数量最小为1"
              maxLength={10}
              min={1}
            />
          </Form.Item>
          <Form.Item label="排序值" name="sortValue" rules={formRules.sortValue}>
            <InputNumber
              precision={0}
              // type="number"
              style={{width: '100%'}}
              placeholder="0~99999之间的数字，值越大排名越靠前"
              maxLength={5}
            />
          </Form.Item>
          <Form.Item label="在售/下架" name="status">
            <Radio.Group>
              <Radio value={LibEnum.PRODUCT_STATUS.UP.value}>在售</Radio>
              <Radio value={LibEnum.PRODUCT_STATUS.DOWN.value}>下架</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="product-form-row">
          <Form.Item label="商品描述" name="productDesc" rules={formRules.productDesc}>
            <Input.TextArea rows={5} style={{width: '100%', border: '1px solid #d9d9d9'}}></Input.TextArea>
          </Form.Item>
          <Form.Item label="搜索关键词" style={{flex: 2, height: 32}}>
            {
              this.state.searchWordList?.map((item, index) => {
                return <Tag key={item + index} color="#daeef7" closable onClose={() => {
                  const next = [...this.state.searchWordList];
                  next.splice(index, 1);
                  this.setState({
                    searchWordList: next,
                  });
                }}>{item}</Tag>
              })
            }
            {
              this.state.showSearchWordInput &&
              <Input
                ref={this.saveInputRef}
                style={{width: 100, height: 32}}
                type="text"
                // size="small"
                placeholder="填写关键词"
                onBlur={(e) => {
                  this.setState({
                    showSearchWordInput: false,
                  });
                  if (e.target.value) {
                    this.setState({
                      searchWordList: Array.from(new Set([...this.state.searchWordList, e.target.value]))
                    });
                  }
                }}
              />
            }
            {
              !this.state.showSearchWordInput &&
              <Button type="primary" icon={<PlusOutlined />} ghost size="small" onClick={this.clickCreateSearchWordBtn}>添加</Button>
            }
          </Form.Item>
        </div>

      </Form>

      {/* 计量单位 */}
      {
        this.props.hideUnit !== true &&
        <>
          <div className="block-title" style={{marginTop: 30}}><i className="iconfont icon-jiliang-mian"/>计量单位</div>
          {
            this.state.unitList.map((item, index) => {
              return <div className="product-form-row" key={item.unitId || item.key}>
                  <Form.Item label={index === 0 ? '主计量单位：' : '辅计量单位'} required>
                  <div className="product-form-unit-row">
                    <Input className={classNames({'product-validate-error': item.unitNameError})} placeholder="单位名称" value={item.unitName} onChange={e => {
                      item.unitName = e.target.value;
                      item.unitNameError = !e.target.value;
                      this.setState({
                        unitList: [...this.state.unitList],
                      });
                    }}/>
                    {
                      item.unitNameError && <span className="unit-name-help">单位名称不能为空</span>
                    }
                    {
                      index > 0 &&
                      <>
                        <span className="ratio-label">换算比例：</span>
                        <InputNumber className={classNames({'product-validate-error': item.ratioError})} precision={0} value={item.ratio} min={2} onChange={value => {
                          item.ratio = Number(value);
                          item.ratioError = value == undefined || value === '';
                          this.setState({
                            unitList: [...this.state.unitList],
                          });
                        }}/>
                        <CloseSquareOutlined className="icon-btn" onClick={() => {
                          this.setState({
                            unitList: [...this.state.unitList],
                          });
                        }}/>
                        {
                          item.ratioError &&
                          <span className="unit-ratio-help">换算比例不能为空</span>
                        }
                      </>
                    }
                  </div>
                </Form.Item>
              </div>
            })
          }
          <Button type="primary" ghost size="small" icon={<PlusOutlined/>} style={{marginLeft: 100}} onClick={this.clickCreateUnitBtn}>添加辅助计量单位</Button>
        </>
      }

      {/* 商品规格 */}
      {
        this.props.hideSku !== true &&
        <>
          <div className="block-title"  style={{marginTop: 30}}><i className="iconfont icon-guige"/>商品规格</div>
          {
            this.state.skuList.map((item, index) => {
              return <div className="product-form-row" key={item.skuId || item.key}>
                <Form.Item label={'规格' + (index + 1)} required style={{marginBottom: 0}}>
                  <div className="product-form-sku-row" key={item.skuId || item.key}>
                    <Input className={classNames({'product-validate-error': item.skuNameError})} placeholder="规格名称" value={item.skuName} onChange={e => {
                      item.skuName = e.target.value;
                      item.skuNameError = !e.target.value;
                      let skuError = this.state.skuError;
                      if (!this.state.skuError) {
                        skuError = item.skuNameError;
                      }
                      this.setState({
                        skuList: [...this.state.skuList],
                        skuError,
                      });
                    }}/>
                    {
                      item.skuNameError && <span className="sku-name-help">规格名称不能为空</span>
                    }
                    <span className="price-label">价格：</span>
                    <InputNumber className={classNames({'product-validate-error': item.priceError})} precision={2} value={item.price ? Number(item.price) : undefined} min={0} placeholder="价格" onChange={value => {
                      item.price = value as any;
                      item.priceError = value === '' || value === undefined;
                      let skuError = this.state.skuError;
                      if (!this.state.skuError) {
                        skuError = item.priceError;
                      }
                      this.setState({
                        skuList: [...this.state.skuList],
                        skuError,
                      });
                    }}/>
                    {
                      item.priceError && <span className="sku-price-help">价格不能为空</span>
                    }
                    <CloseSquareOutlined className="icon-btn" onClick={() => {
                      this.setState({
                        skuList: [...this.state.skuList],
                      });
                    }}/>
                  </div>
                </Form.Item>
              </div>
            })
          }
          <Button type="primary" ghost size="small" icon={<PlusOutlined/>} style={{marginLeft: 100, marginTop: 5}}  onClick={this.clickCreateSkuBtn}>添加商品规格</Button>
        </>
      }

      {/* 图片 */}
      {
        this.props.hideImage !== true &&
        <>
          <div className="block-title" style={{marginTop: 30}}>
            <i className="iconfont icon-tupian"/> 商品图片
            <span className="block-title-desc">（选择的图片单张大小不超过1MB，支持jpg，jpeg，png）</span>
          </div>
          <div className="product-form-row">
            <Form.Item label="主图" required validateStatus={this.state.imageError ? 'error' : 'validating'}>
              <div style={{padding: 6, marginBottom: 15}}>(支持最多上传 1 张，建议尺寸375 x 375)</div>
              <UploadImage validateStatus={this.state.imageError ? 'error' : 'validating'} extendData={{type: 'logo', objectId: this.props.productId}} maxLength={1} ref={this.logoImage} onChange={this.changeFileList} fileList={[]}/>
              {
                this.state.imageError && <div className="image-help">主图必须上传</div>
              }
            </Form.Item>
          </div>
          <div className="product-form-row">
              <Form.Item label="轮播图">
                <div style={{padding: 6, marginBottom: 15}}>(支持最多上传 10 张，建议尺寸750 x 750)</div>
                <UploadImage sortable extendData={{type: 'slider', objectId: this.props.productId}} ref={this.slideImage} fileList={[]}/>
              </Form.Item>
          </div>
          <div className="product-form-row">
            <Form.Item label="详情图">
              <div style={{padding: 6, marginBottom: 15}}>(支持最多上传 10 张，建议尺寸750 x 750)</div>
              <UploadImage sortable extendData={{type: 'detail', objectId: this.props.productId}} ref={this.detailImage} fileList={[]}/>
            </Form.Item>
          </div>
        </>
      }
    </div>
  }

  private changeFileList = fileList => {
    this.setState({
      imageError: fileList.length === 0
    });
  }

  // 点击新增搜索关键词的按钮
  private clickCreateSearchWordBtn = () => {
    this.setState({
      showSearchWordInput: true,
    }, () => {
      this.saveInputRef.current?.focus();
    });
  }

  // 点击新增计量单位的按钮
  private clickCreateUnitBtn = () => {
    const unitError = this.state.unitList.some(item => item.unitNameError || item.ratioError);
    this.setState({
      unitList: [
        ...this.state.unitList, {
          unitName: undefined,
          ratio: undefined,
          key: v4(),
          unitNameError: unitError,
          ratioError: unitError,
        }],
    });
  }

  // 点击新增规格的按钮
  private clickCreateSkuBtn = () => {
    const skuError = this.state.skuError;
    this.setState({
      skuList: [
        ...this.state.skuList, {
          skuName: undefined,
          price: undefined,
          key: v4(),
          skuNameError: skuError,
          priceError: skuError,
        }
      ],
    });
  }
  
  public reset = () => {
    this.form?.resetFields();
    this.setState({
      searchWordList: [],
      unitList: [{
        unitName: undefined,
        ratio: 1,
        key: v4(),
        unitNameError: false,
        ratioError: false,
      }],
      unitError: false,
      skuList: [{
        skuId: undefined,
        skuName: undefined,
        price: undefined,
        key: v4(),
        skuNameError: false,
        priceError: false,
      }],
      skuError: false,
      showSearchWordInput: false,
    });
  }

  public validateFields = () => {
    // 验证单位
    let unitError = false;
    for (let unit of this.state.unitList) {
      unit.unitNameError = !unit.unitName;
      unit.ratioError = unit.ratio === undefined || Number(unit.ratio) == 0;
      if (!unitError) {
        unitError = unit.ratioError;
      }
    }

    // 验证规格
    let skuError = false;
    for (let sku of this.state.skuList) {
      sku.skuNameError = !sku.skuName;
      sku.priceError = sku.price === undefined || sku.price === '';
      if (!skuError) {
        skuError = sku.skuNameError || sku.priceError;
      }
    }

    this.setState({
      unitList: [...this.state.unitList],
      skuList: [...this.state.skuList],
      skuError,
      imageError: this.logoImage.current?.getImageIdList().length === 0,
    });

    return this.form?.validateFields().then(values => {
      if (unitError) {
        return Promise.reject('');
      }
      if (skuError) {
        return Promise.reject('');
      }
      if (this.logoImage.current?.getImageIdList().length === 0) {
        return Promise.reject('');
      }
      return {
        productName: values.productName,
        categoryId: values.categoryId,
        status: values.status,
        searchWordList: this.state.searchWordList,
        productDesc: values.productDesc,
        sortValue: values.sortValue,
        barcode: values.barcode,
        minBuyCount: values.minBuyCount,
        imageLogoId: this.logoImage.current?.getImageIdList()[0] as string,
        imageSlideIdList: this.slideImage.current?.getImageIdList() as string[],
        imageDetailIdList: this.detailImage.current?.getImageIdList() as string[],
        unitList: this.state.unitList.map(item => {
          return {
            unitId: item.unitId,
            ratio: item.ratio,
            unitName: item.unitName,
          };
        }) as LibIProductUnit[],
        skuList: this.state.skuList.map(item => {
          return {
            skuId: item.skuId,
            skuName: item.skuName,
            price: item.price,
          };
        }) as {skuId: string; skuName: string; price: string | number}[],
      };
    });
  }
}
