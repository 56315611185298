import { Button, Layout } from "@/components";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import { Space } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ProductForm from "../components/ProductForm";
import ProductDetailMobx from '../ProductDetailMobx';

@observer
export default class ProductUpdatePage extends React.PureComponent<RouterPropsType> {
  private productForm = React.createRef<ProductForm>();

  private productMobx = new ProductDetailMobx();

  state = {
    loading: false,
  }

  componentDidMount() {
    this.productMobx.findDetail(this.props.match?.params.id)
  }

  render() {
    return <Layout.Page flexColumn overHidden>
      <Layout flex1 scrollY>
        {
          this.productMobx.productName &&
          <ProductForm ref={this.productForm}
            productId={this.productMobx.productId}
            productName={this.productMobx.productName}
            status={this.productMobx.status}
            categoryId={this.productMobx.categoryId}
            barcode={this.productMobx.barcode}
            sortValue={this.productMobx.sortValue}
            minBuyCount={this.productMobx.minBuyCount}
            productDesc={this.productMobx.productDesc}
            searchWordList={toJS(this.productMobx.searchWordList)}
            hideUnit
            hideSku
            hideImage
          />
        }
      </Layout>
      <Layout.FootBar>
        <Space>
          <Button type="ghost" onClick={() => {
            this.props.history.close();
          }}>取消</Button>
          <Button type="primary" disabled={this.state.loading} loading={this.state.loading} onClick={this.clickSave}>保存</Button>
        </Space>
      </Layout.FootBar>
    </Layout.Page>
  }

  private clickSave = () => {
    if (this.state.loading) return;
    this.productForm.current?.validateFields()?.then((values) => {
      this.setState({
        loading: true,
      });
      WebApi
      .product_update({
        productId: this.props.match?.params.id,
        productName: values.productName,
        categoryId: values.categoryId,
        barcode: values.barcode,
        sortValue: values.sortValue,
        minBuyCount: values.minBuyCount,
        productDesc: values.productDesc,
        status: values.status,
        searchWordList: values.searchWordList,
        skuList: values.skuList,
        unitList: values.unitList,
      })
      .then(() => {
        const event = new Event(routerMap.product_detail.getPath(this.props.match?.params.id));
        document.dispatchEvent(event);
        showSuccess.save();
        this.props.history.close();
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          loading: false,
        });
      })
    })
  }
}