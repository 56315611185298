import { Rule } from "rc-field-form/lib/interface";

export default {
  productName: [{
    required: true,
    message: '填写商品名称',
  }],
  categoryId: [
    {
      required: true,
      message: '选择分类',
    },
  ],
  productDesc: [
    {
      required: true,
      message: '填写商品描述',
    },
  ],
  minBuyCount: [
    {
      required: true,
      type: 'number',
      max: 999999999,
      min: 1,
      transform: value => Number(value),
      message: '请使用整数',
    },
  ],
  sortValue: [
    {
      required: true,
      type: 'number',
      max: 99999,
      min: 0,
      transform: value => Number(value),
      message: '请填写0~99999之间的数字',
    },
  ]
} as {
  [name: string]: Rule[]
}